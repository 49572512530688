import { env } from 'md-base-tools/env';
import { urlToJson } from '@/utils/tools';
import { facePrintAuthResult } from '@/api';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      loading: true,
      fail: true,
      user_id: '',
      msg: '',
      nextUrl: ''
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  async beforeMount() {
    document.title = '验证结果';
    // const userId = this.$route.params.id ? this.$route.params.id.replace('.html', '') : ''

    const query = this.$route.query ? this.$route.query : {};
    if (query && query.from === 'verify') {
      if (query && query.result === 'success') {
        this.fail = false;
      } else {
        this.fail = true;
      }
      this.loading = false;
      this.msg = query.message ? query.message : '';
      return;
    }
    const search = window.location.search.substr(1);
    const searchObj = urlToJson(search);
    if (this.userId && Number(this.userId) && Number(this.userId) > 0) {
      this.fail = false;
      const {
        status,
        message
      } = await facePrintAuthResult({
        user_id: this.userId
      });
      if (status === 0) {
        this.msg = false;
      } else {
        this.fail = true;
      }
      this.msg = message;
      this.loading = false;
    } else {
      this.loading = false;
      this.fail = true;
    }
    this.nextUrl = searchObj.nextUrl || '';
  },
  methods: {
    /* eslint-disable */
    goBack() {
      graft.app({
        functionName: "closeController",
        webFun: e => {
          if (env.isWxApp()) {
            wx.miniProgram.navigateBack();
          } else {
            history.go(-2);
          }
        }
      });
    }
  }
};