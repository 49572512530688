import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@assets/images/identity/identity_fail@2x.png';
import _imports_1 from '@assets/images/identity/identity_success@2x.png';
const _withScopeId = n => (_pushScopeId("data-v-62848e16"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "identity-cont"
};
const _hoisted_2 = {
  key: 0,
  src: _imports_0
};
const _hoisted_3 = {
  key: 1,
  src: _imports_1
};
const _hoisted_4 = {
  class: "identity-result"
};
const _hoisted_5 = {
  class: "identity-tips"
};
const _hoisted_6 = {
  class: "btn-info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_loading = _resolveComponent("van-loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.loading ? (_openBlock(), _createBlock(_component_van_loading, {
    key: 0,
    type: "spinner",
    size: "66"
  })) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [$data.fail ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3))], 64)), _createElementVNode("div", _hoisted_4, "身份核验" + _toDisplayString($data.loading ? '中' : $data.fail ? '失败' : '成功'), 1), _createElementVNode("div", _hoisted_5, _toDisplayString($data.msg), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
    class: "btn",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.goBack && $options.goBack(...args))
  }, "返回")])]);
}